import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

// Console Web Imports
import { LaunchpadService } from '../launchpad.service';
import { IEnvironment } from '../../shared/models';

@Component({
  selector: 'app-environment-selector',
  templateUrl: './environment-selector.component.html',
  styleUrl: './environment-selector.component.scss'
})
export class EnvironmentSelectorComponent implements OnInit {
  /**
   * Constant to hold the value of the selected account id. There are 2 possible
   * scenarios for obtaining the desired account id value:
   * 
   * - Scenario 1: The user makes a selection from the list of available account ids on the screen.
   * 
   * - Scenario 2:
   *   The account id has been provided as a URL parameter from a redirection and/or navigation event.
   */
  private selectedAccountId: string | null = null;
  private selectedEnvironment!: IEnvironment;
  public pageTitle: string = 'Select an Environment';
  public isLoading: boolean = true;
  public showError: boolean = false;
  public environments: IEnvironment[] = [];
  public errorMessage: string = 'Unable to retrieve environment information, please try again.'

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    private launchpadService: LaunchpadService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.getAccountId();
    if (!this.selectedAccountId) {
      // Account ID has not been provided, redirecting to account selection screen.
      await this.redirectToAccountSelection();
      return;
    } else {
      // Account ID has been provided, getting environment information by accountId.
      await this.getEnvironments();
      return;
    }
    
  }

  /**
   * Utility method for handling the environment selection event.
   * @param environment User selected environment.
   * @returns void
   */
  public async selectEventHandler(environment: IEnvironment): Promise<void>{
    try {
      this.showError = false;
      this.isLoading = true;
      this.selectedEnvironment = environment;
      const appianURl = await this.getAppianUrlbyEnvironmentId(environment.id);
      if (appianURl === '') {
        this.showError = true;
        this.isLoading = false;
        return;
      }
      await this.navigateToAppian(appianURl);

    } catch(error: any) {
      console.error(`Error: unable to handle selection`);
    }
  }

  /**
   * Utility method for redirecting the user to the correct Appian instance
   * based on the environment selected by the user.
   * @param targetUrl 
   */
  private async navigateToAppian(targetUrl: string): Promise<void> {
      try {
        this.document.defaultView!.location.href = targetUrl;
      } catch (error: any) {
        console.error(`ERROR: redirectHandler`);
      }
  }

  /**
   * Utility method for retrieving the selected account id based on url params
   *  - option 1: get account id from url params.
   *  - option 2: get account id from the account service.
   * @returns void
   */
  private getAccountId(): void {
    try {
      // Attempt to retrieve the accountId from the URL parameter
      const selectedAccountId = this.route.snapshot.paramMap.get('accountId');
      if (selectedAccountId) {
        this.selectedAccountId = selectedAccountId;
        return;
      }

      // Attempt to retrieve the selected account from the service
      const selectedAccount = this.launchpadService.getSelectedAccount();
      if (selectedAccount?.id) {
        this.selectedAccountId = selectedAccount.id;
        return;
      }
    } catch(error: any) {
      console.error(`Error: unable to retrieve environment information`);
    }
  }

  /**
   * Utility method for querying the console API to get a list of available environments
   * associated with a given platform account, the account selected by the user.
   */
  private async getEnvironments(): Promise<void> {
    try {
      if (!this.selectedAccountId) {
        throw Error('AccountId is invalid.');
      }
      this.environments = await this.launchpadService.getEnvironmentsByAccountIdForLoggedInUser(this.selectedAccountId);
      this.isLoading = false;
      
    } catch(error: any) {
      console.error(`Error: Unable to retrieve environment information`);
      console.error(error);
    }
  }

  /**
   * Utility method for querying the url of the appian instance associated with the 
   * account environment selected by the user.
   * @param id Selected "customer" environment id.
   * @returns The url for the appian instance associated with the selected environment
   */
  private async getAppianUrlbyEnvironmentId(id: number): Promise<string> {
    try {
      const appianUrl = await this.launchpadService.getAppianUrlByEnvironmentId(id);
      return appianUrl;
    } catch(error: any) {
      console.error(`Error: Unable to retrieve enevironment information`);
      console.error(error);
      return '';
    }
  }

  /**
   * Helpler method for redirecting a user to the account selection screen.
   */
  private async redirectToAccountSelection(): Promise<void> {
    await this.router.navigate(['accounts']);
  }
}
