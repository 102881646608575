import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LaunchpadService } from '../launchpad.service';
import { IAccountTest } from '../../shared/models';
import { AuthenticationService } from '../../authentication/authentication.service';

@Component({
  selector: 'app-launchpad',
  templateUrl: './launchpad.component.html',
  styleUrl: './launchpad.component.scss'
})
export class LaunchpadComponent implements OnInit{
  private userAccounts: IAccountTest[] = [];

  constructor(
    private launchPadService: LaunchpadService,
    private authService: AuthenticationService,
    private router: Router,
  ) {}

  /**
   * Initiates the retrieval of account information and login flow initialization.
   */
  public async ngOnInit(): Promise<void> {
    try {
      this.authService.sessionPolling();
      await this.getAccountInformation();
      this.initializeLoginFlow();
    } catch (error) {
      console.error('Initialization failed:', error);
    }
  }

  /**
   * Fetches the user profile and account information.
   */
  private async getAccountInformation(): Promise<void> {
    try {
      this.userAccounts = await this.launchPadService.getAccountsForLoggedInUser();
    } catch (error) {
      console.error('Unable to retrieve account information:', error);
      throw error;
    }
  }

  /**
   * Initializes the login flow based on the number of user accounts.
   * Redirects to the appropriate page based on the account count.
   */
  private initializeLoginFlow(): void {
    if (this.userAccounts.length > 1) {
      this.router.navigate(['accounts']);
    } else {
      this.launchPadService.setSelectedAccount(this.userAccounts[0]);
      this.router.navigate(['environments']);
    }
  }
}
