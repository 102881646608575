import { Injectable } from '@angular/core';
import { HttpService } from '../shared/http.service';
import { ApiResponse, ApiAccountsResults, IAccountItem, IAccountTest, ApiEnvironmentResults, IEnvironment } from '../shared/models';
import { HttpParams } from '@angular/common/http';
import { UserClaims, CustomUserClaims } from '@okta/okta-auth-js';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LaunchpadService {
  /* Stores the current user profile informartion. */
  private userProfile: UserClaims<CustomUserClaims> | undefined;
  /* Store the value of the selected account id.*/
  private selectedAccount: IAccountTest | undefined;
  /* current user available accounts */
  private userAccounts: IAccountTest[] | undefined;
 
  constructor(
    private http: HttpService,
    private authService: AuthenticationService,
  ) { }

  /**
   * Sets the currently selected account.
   * @param account - The account to be selected.
   */
  public setSelectedAccount(account: IAccountTest): void {
    this.selectedAccount = account;
  }
  
  /**
   * Retrieves the currently selected account.
   * @returns The selected account.
   */
  public getSelectedAccount(): IAccountTest {
    return this.selectedAccount!;
  }

  public getCurrentUserAccounts(): IAccountTest[] {
    if (this.userAccounts) {
      return this.userAccounts;
    }
    return [];
  }

  /**
   * Fetches accounts associated with the given user ID.
   * @param userId - The ID of the user whose accounts are to be fetched.
   * @returns A list of accounts associated with the user.
   */
  public async getAccountsForLoggedInUser(): Promise<IAccountTest[]> {
    try {
      const url = `${environment.baseApiHost}/v1/users/me/accounts`;
      const response = await this.http.get<ApiResponse<ApiAccountsResults>>(url);
      this.userAccounts = this.accountFactory(response.content.results);
      return this.userAccounts;
    } catch (error) {
      console.error('Error retrieving account information:', error);
      return [];
    }
  }

  /**
   * Fetches environments for the specified account ID.
   * @param accountId - The ID of the account whose environments are to be fetched.
   * @returns A list of environments for the given account.
   */
  public async getEnvironmentsByAccountIdForLoggedInUser(accountId: string): Promise<IEnvironment[]> {
    try {
      const url = `${environment.baseApiHost}/v1/environments/me/${accountId}`;
      const response = await this.http.get<ApiResponse<ApiEnvironmentResults>>(url);
      return response.content.results;
    } catch (error) {
      console.error('Error retrieving environments:', error);
      return [];
    }
  }

  /**
   * Fetches the Appian URL for the specified environment ID.
   * @param envId - The environment ID whose URL is to be fetched.
   * @returns The Appian URL as a string.
   */
  public async getAppianUrlByEnvironmentId(envId: number): Promise<string> {
    try {
      const url = `${environment.baseApiHost}/v1/environments/${envId.toString()}/url`;
      const response = await this.http.getWithRetry<ApiResponse<string>>(url, 2);
      return response.content;
    } catch (error) {
      console.error('Error retrieving Appian URL:', error);
      return '';
    }
  }

  /**
   * Transforms an array of IAccountItem objects into an array of IAccountTest objects.
   *
   * @param accounts - An array of IAccountItem objects to be transformed.
   * @returns An array of IAccountTest objects.
   *
   * @remarks
   * Each IAccountItem is expected to contain an `account` property, which holds
   * the relevant data to be extracted and mapped into an IAccountTest object.
   */
  private accountFactory(accounts: IAccountItem[]): IAccountTest[] {
    return accounts.map(item => ({
      status: item.account.status,
      id: item.account.id,
      name: item.account.name,
      tenantId: item.account.tenantId,
      description: item.account.description,
      primary: item.primary,
      logoUrl: item.account.logoUrl,
    }));
}

}
