import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Application imports
import { LaunchpadService } from '../launchpad.service';
import { IAccountTest } from '../../shared/models';


@Component({
  selector: 'app-account-selector',
  templateUrl: './account-selector.component.html',
  styleUrl: './account-selector.component.scss'
})
export class AccountSelectorComponent implements OnInit {
  public pageTitle: string = 'Select an Account';
  public isLoading: boolean = true;
  public accounts: IAccountTest[] = [];
  
  
  constructor(
    private router: Router,
    private launchPadService: LaunchpadService,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.getUserAccountInfo();
  }

  /**
   * This method handles the user generated event of selecting an environment.
   * @param account User selected account
   */
  public async selectEventHandler(account: IAccountTest): Promise<void> {
    try {
      this.launchPadService.setSelectedAccount(account);
      await this.router.navigate(['environments']);
    } catch (error: any) {
      console.error(`Error: Unable to handle selection event.`);
      console.error(error);
    }
  }

  /**
   * Helper method for validating if a given string is a valid url
   * @param urlString URL string to validate
   * @returns a boolean flag to determine if the url is valid
   */
  public isValidUrl(urlString: string): boolean {
    try {
      new URL(urlString);
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Utility method for quesrying the API for all account associated with a given user.
   */
  private async getUserAccountInfo(): Promise<void> {
    const userAccounts: IAccountTest[] = await this.launchPadService.getAccountsForLoggedInUser();
    this.accounts = userAccounts;
    this.isLoading = false;
  }
}
